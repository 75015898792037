export default{
    data(){
        return{
            isPhone: this.$store.state.isphone
        }
    },
    watch:{
        '$store.state.isphone'(val){
            this.isPhone=val
        }
    }
}