<template>
  <router-view/>
</template>


<script>
export default{
  methods: {
    /** 判断是否为手机 */
    isphone(){
        window.fullWidth = document.documentElement.clientWidth;
        if (window.fullWidth < 769) {
          this.$store.commit('setisphone', true)
        } else {
          this.$store.commit('setisphone', false)
        }
    }
  },
  mounted() {
    //获取宽度判断手机还pc
    this.isphone()
    window.addEventListener('resize', ()=> {
      return setTimeout(()=>{
        this.$nextTick(()=>{
          this.isphone();
        })
      },500) 
    }, false);
  }
}
</script>
<style lang="scss">

body{
margin: 0;
}
</style>
