import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './uilt/flexible'

import useVant from './uilt/vant'
const app=createApp(App)
useVant(app)
app.use(ElementPlus)
app.use(store).use(router)
app.mount('#app')
