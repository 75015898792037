<template>
    <div class="header">
        <img class="header__logo" src="@/assets/logo.png" alt="">
        <div v-if="isPhone" class="model">
            <img class="model__list" src="@/assets/list.png" alt="" @click="changeListState" />
        </div>
        <div v-if="!isPhone" class="header__nav">
            <router-link v-for="(item, index) in navlist" :key="index" class="header__nav__h" :to="item.path">{{
        item.name
}}</router-link>
        </div>
        <van-popup v-if="isPhone" position="top" v-model:show="show">
            <div class="header__box">
                <router-link @click="changeListState" v-for="(item, index) in navlist" :key="index" class="header__nav__h" :to="item.path">{{
                    item.name
            }}</router-link>
            </div>
        </van-popup>
    </div>
    <router-view></router-view>
    <footer class="footer">
        <div class="footerBox">
            <div class="footer__text">© 2015 - 2022 南京天行健数据科技有限公司 版权所有 苏ICP备2022038955号-1</div>
            <div class="footer__text">公司地址: 南京市雨花台区软件大道 46 号 2 栋 2416 室 
                 <div class="call">电话: 13813820850</div> 
                  <div class="call">邮箱: 459651282@qq.com</div> 
                </div>
        </div>
    </footer>
</template>

<script>
import isphone from '@/uilt/isphone'
export default {
    mixins:[isphone],
    data() {
        return {
            show: false,
            navlist: [
                {
                    path: '/home',
                    name: '首页'
                },
                {
                    path: '/product',
                    name: '产品和服务'
                },
                {
                    path: '/solution',
                    name: '解决方案'
                },
                {
                    path: '/success',
                    name: '合作客户'
                },
                {
                    path: '/legal',
                    name: '项目案例'
                },
                {
                    path: '/about',
                    name: '联系我们'
                },
            ],
        }
    },
    methods: {
        changeListState() {
            this.show = !this.show;
        },
    }
}
</script>

<style lang="scss" scoped>
a {
    text-decoration: none;
    color: #3D3D3D;
}

@media screen  and (min-width: 769px){
.header {
    width: 100%;
    height: 64px;
    padding: 0 220px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;

    &__logo {
        object-fit: cover;
        height: 60px;
    }
    &__nav {
        &__h {
            margin: 0 10px;
            font-size: 20px;

            &:hover {
                color: #5076CC;
            }
        }
    }
    .router-link-active {
        color: #000;
        font-weight: 700;
    }
}
.footer {
    height: 156px;
    width: 100%;
    background-color: #5076CC;
    display: flex;
    justify-content: center;
    align-items: center;
.call{
    display: inline;
}
    font-size: 18px;
    &__text {
        color: #fff;
        margin: 6px;
    }
}
}

@media screen and (max-width: 768px) {
    .header{
        display: flex;
        justify-content: space-between;
        padding: 5px 15px;
        box-sizing: border-box;
        align-items: center;
        height: 50px;
        ::v-deep{
            .van-popup--top{
                top: 50px !important;
            }
            .van-overlay {
                margin-top: 50px;
                background-color: rgba($color: #fff, $alpha: 0.6);
            }
        }
        &__logo{
            width: 103px;
            height: 40px;
        }
        .model{
            &__list{
                width: 24px;
                height: 24px;
            }
        }
        &__box{
            display: flex;
            flex-direction: column;
            a{
                display: block;
                height: 59px;
                text-align: center;
                width: 375px;
                line-height: 59px;
            }
            .router-link-active {
                color: rgb(72, 148, 247);
                font-weight: 700;
                text-decoration: underline solid rgb(72, 148, 247) 2px;
            }
        }
    }
    .footer {
        .call{
            display:block;
        }
        height: 173px;
        width: 100%;
        background-color: #5076CC;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 13px;
        border-top: 1px solid rgb(196, 213, 255);
        &__text {
            color: #fff;
            line-height: 20px;
            margin: 6px;
        }
    }
}


.footerBox {}
</style>