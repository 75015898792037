import { createRouter, createWebHistory } from 'vue-router'
import NavView from '@/components/NavView.vue'
const routes = [
  {
    path: '',
    component: NavView,
    redirect:'/home',
    children:[
      {
        path:'/home',
        name:'home',
        component:()=>import('@/views/HomeView.vue')
      },
      {
        path:'/product',
        name:'Product',
        component:()=>import('@/views/ProductView.vue')
      },
      {
        path:'/solution',
        name:'Solution',
        component:()=>import('@/views/SolutionView.vue')
      },
      {
        path:'/success',
        name:'Success',
        component:()=>import('@/views/SuccessView.vue')
      },
      {
        path:'/legal',
        name:'Legal',
        component:()=>import('@/views/LegalView.vue')
      },
      {
        path:'/about',
        name:'About',
        component:()=>import('@/views/AboutView.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
