import { createStore } from 'vuex'

export default createStore({
  state: {
    isphone:false,
    config:{}
  },
  mutations: {
    setisphone(state,e){
      this.state.isphone=e
    }
  },
  actions: {
    setconfig(state,e){
      this.state.config=e
    }
  },
  modules: {
  }
})
